import { ROUTES } from '@/controllers/router/router.contants';

interface Options {
  source: string;
  courseSlug?: string;
}

export const getSignUpLink = (options: Options) => {
  const {
    source,
    courseSlug,
  } = options;

  const courseQuery = courseSlug
    ? `&courseSlug=${courseSlug}`
    : '';

  return String.prototype.concat(
    ROUTES.registration.index,
    `?source=${source}`,
    courseQuery,
  );
};
